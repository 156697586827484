<template>
  <div id="home">
    <van-nav-bar
        title="关于我们"
        left-text="返回"
        left-arrow
        fixed
        @click-left="goback('/home')"
    />
    <van-image class="a-us" :src="banner"></van-image>
    <div class="aboutus" v-html="aboutus"></div>
    <div class="c-us">热线电话：18950135000</div>
  </div>
</template>

<script>
import {onMounted, reactive, ref, toRefs, computed} from 'vue';
import {httpget} from "@/libs/api";
import {useStore} from "vuex";
import { useRouter } from 'vue-router'
import {Toast,Dialog} from "vant";

export default {
  name: 'Aboutus',
  setup() {
    const store=useStore();
    const router = useRouter();
    const state = reactive({
      banner: require('@/assets/default/imgs/banner.jpg'),
      aboutus:'',
      loading:false
    })
    const getinfo = async () => {
      state.loading = true;
      let res = await httpget('/api/index/aboutus');
      if(res.code===0){
        state.loading = false;
        state.aboutus = res.data;
      }else{
        state.loading = false;
        state.finished = true;
        return ;
      }
    }
    onMounted(() => {
      getinfo();
    })
    return {
      ...toRefs(state),
      getinfo
    };
  },
}
</script>
